import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { useSnackbar } from 'notistack';
import { jwtDecode } from 'jwt-decode';
import { LoginParams, useLoginMutation } from 'api/mutations/auth/login';
import { useGetSelfQuery } from 'api/queries/users/get-self';
import { ACCESS_TOKEN, ACTIVE_PROJECT_ID, REFRESH_TOKEN } from 'constants/localStorageKeys';
import { UserRole } from 'utils/enums';
import { useLogoutMutation } from 'api/mutations/auth/logout';
import { handleAxiosError } from 'utils/axiosErrorHandler';
import { ProjectData, UserData } from 'api/types';
import { useProjectGetOneQuery } from '../api/queries/projects/get-one';
import useMyProjectsSelector from '../api/selectors/my-projects';

type OnLogoutParams = {
  redirectToRoot?: boolean;
};

type AuthContextType = {
  token?: string;
  selectedProjectId?: string | null;
  role?: UserRole;
  isAuthorised: boolean;
  onAuth(params: LoginParams): void;
  onProjectChange(projectId: string): void;
  onLogout(params?: OnLogoutParams): void;
  meta?: {
    id: string;
    fullName: string;
    projectName: string;
  };
  isLoading: boolean;
};

const onLogoutDefaultParams = { redirectToRoot: true };

const defaultAuthState: AuthContextType = {
  token: undefined,
  role: undefined,
  isAuthorised: false,
  onAuth: () => {},
  onLogout: () => {},
  onProjectChange: () => {},
  isLoading: false,
};

const getMeta = (token?: string, userData?: UserData, projectData?: ProjectData) => {
  if (!token) return undefined;

  const id = jwtDecode<{ sub: string }>(token)?.sub;
  const fullName = [userData?.firstName, userData?.lastName].filter(Boolean).join(' ') || 'N/A';
  const projectName = projectData?.name || 'N/A';

  return { id, fullName, projectName };
};

const AuthContext = createContext<AuthContextType>(defaultAuthState);

export default function AuthProvider({ children }: PropsWithChildren) {
  const [authState, setAuthState] = useState<Pick<AuthContextType, 'token' | 'selectedProjectId'>>(() => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    return token ? { token, selectedProjectId: localStorage.getItem(ACTIVE_PROJECT_ID) } : defaultAuthState;
  });
  const { data: userData, isLoading: isUserDataLoading, refetch: refetchUserData } = useGetSelfQuery(!!authState.token);
  const { data: projectData, isLoading: isProjectDataLoading } = useProjectGetOneQuery(
    authState.selectedProjectId || '',
    !!authState.selectedProjectId,
  );
  const { mutateAsync: login } = useLoginMutation();
  const { mutateAsync: logout } = useLogoutMutation();
  const { enqueueSnackbar } = useSnackbar();

  const onAuth = (params: LoginParams) => {
    return login(params)
      .then(async ({ accessToken, refreshToken }) => {
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        localStorage.removeItem(ACTIVE_PROJECT_ID);
        setAuthState({ token: accessToken, selectedProjectId: undefined });

        const { data: userData } = await refetchUserData();
        const meta = getMeta(accessToken, userData);

        ReactGA.event('login', {
          title: 'Login',
          userId: meta?.id,
          userName: meta?.fullName,
          userRole: userData?.roles?.[0],
        });
      })
      .catch(handleAxiosError)
      .catch((error: AxiosError) => {
        const { status } = error?.response || {};
        if (status && [401, 404].includes(status)) {
          enqueueSnackbar('Invalid credentials', { variant: 'warning' });
        }
      });
  };

  const onLogout = useCallback(
    (params: OnLogoutParams) => {
      const { redirectToRoot } = { ...onLogoutDefaultParams, ...params };

      return logout().finally(() => {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(ACTIVE_PROJECT_ID);
        setAuthState(defaultAuthState);
        if (redirectToRoot) location.pathname = '/';
      });
    },
    [logout],
  );

  const onProjectChange = useCallback((projectId: string) => {
    localStorage.setItem(ACTIVE_PROJECT_ID, projectId);
    setAuthState((prev) => ({
      ...prev,
      selectedProjectId: projectId,
    }));
  }, []);

  const [projects] = useMyProjectsSelector(!!authState.token);
  const shouldBeSelectedProject = !!authState.token && !authState.selectedProjectId && !!projects.length;

  useEffect(() => {
    if (shouldBeSelectedProject) {
      onProjectChange(String(projects[0].id));
    }
  }, [shouldBeSelectedProject, onProjectChange, projects]);

  const meta = useMemo(() => {
    return getMeta(authState.token, userData, projectData);
  }, [authState, userData, projectData]);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        role: userData?.roles?.[0],
        isAuthorised: !!authState.token,
        onAuth,
        onLogout,
        onProjectChange,
        isLoading: isUserDataLoading || isProjectDataLoading,
        meta,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
