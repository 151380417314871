import { Outlet, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { useAuth } from 'context/auth';
import Loader from 'components/UI/Loader';
import { RouterPath } from 'navigation/types';

export default function HomePage() {
  const { pathname } = useLocation();
  const { isLoading, role, meta } = useAuth();

  useEffect(() => {
    // wait for the user and project data to load
    if (isLoading) return;

    const pageTitleMap: Partial<Record<RouterPath, string>> = {
      '/': 'Dashboard',
      '/invoices': 'Invoices',
      '/agreements': 'Agreements',
      '/calendar': 'Team Calendar',
      '/resources': 'Available resources',
    };

    const title = pageTitleMap[pathname as RouterPath];

    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      title,
      userId: meta?.id,
      userName: meta?.fullName,
      userRole: role,
      projectName: meta?.projectName,
    });
  }, [isLoading, meta, pathname, role]);

  useEffect(() => {
    ReactGA.event({
      action: 'user_info',
      category: 'Info',
      label: `PROJECT_NAME: ${meta?.projectName}, FULL_NAME: ${meta?.fullName}, ROLE: ${role}, PATH: ${pathname}`,
    });
  }, [meta, pathname, role]);

  return isLoading ? <Loader /> : <Outlet />;
}
